import {ref, reactive, toRefs, computed, defineComponent, onBeforeMount, onMounted, getCurrentInstance, provide, nextTick} from 'vue';
import JsshpsbListUtil ,{IJsshpsbListDataObj} from './jsshpsbListUtil';

export default defineComponent ({
    name: 'JsshpsbList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:IJsshpsbListDataObj=reactive<IJsshpsbListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'技术审核订单评审表',
                isShowFixCol:false,
                paramHeight:54,//tab的高度
                queryParam: {
                    queryType:'all',
                    cardFrom:'JsshpsbList',
                },
                modelMethod: utils.OrderProviderApi.buildUrl('/splitOrder/pageData')
            },
            otherParams:{
                belongMaxId:'',
                activeName:'all',
                visible:false,//订单评审弹出框隐藏/显示控制标识
                processInfo:{
                    visible:false
                }
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new JsshpsbListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.otherParams.belongMaxId,
                    fileSize:80,
                    accept:'.xlsx,.xls',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [
                        {value:0,label:'通过'},{value:1,label:'退回'}
                    ];
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //维护订单评审表按钮事件
        const ddpsbHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                dataObj.otherParams.visible=true;
                //只是这样修改了，在upload组件中不会改变belongMaxId的值，必须在nextTick里面显示的改变upload中的belongMaxId，才会发起加载附件的请求
                //否则每行记录打开的订单评审表都一样
                // dataObj.otherParams.belongMaxId=selectRows[0].F_ID;
                nextTick(()=>{
                    dataObj.refMap.get('ddpsb').uploadParams.belongMaxId=selectRows[0].F_ID;
                    dataObj.refMap.get('ddpsb').uploadParams.disabled=true;
                })
            }
        }
        //提交
        const shHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                dataObj.otherParams.processInfo.id=selectRows[0]['F_ID'];
                dataObj.otherParams.processInfo.visible=true;
            }
        }
        //审核结果
        const process=async ()=>{
            await dataObj.utilInst.doProcess();
        }
        //tab切换事件
        const handleChange=(tabName:string)=>{
            dataObj.pageList.queryParam.queryType=tabName;
            dataObj.pageListRef.queryHandler(true);
        }
        //表格加载完毕
        const gridLoaded=(res:any)=>{
            dataObj.pageListRef.listButtons = res.buttons;//为列表头部的按钮赋值
        }
        return{
            ...toRefs(dataObj),buildUploadInitParams,comboSelect,handlePreview,ddpsbHandler,shHandler,process,handleChange,gridLoaded
        }
    }
});