import BaseBean from "@/utils/BaseBean";

export interface IJsshpsbListDataObj {
    utilInst:JsshpsbListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class JsshpsbListUtil extends BaseBean{
    public dataObj:IJsshpsbListDataObj

    constructor(proxy:any,dataObj:IJsshpsbListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //得到的选择行
    public getSelectedRow():any{
        let selectRows = this.dataObj.pageListRef.getTbInst().getSelection();
        if (selectRows.length == 0) {
            this.proxy.$message('请选择要操作的行');
            return;
        }
        return selectRows;
    }
    //审核
    public async doProcess():Promise<void>{
        let status:any=this.dataObj.otherParams.processInfo.status;
        if(!(status==0 || status==1)){
            this.proxy.$message('请选择结果');
            return ;
        }
        let res=await this.utils.OrderProviderApi.processPiDdpsb({
            params:{
                type:'JsshpsbList',
                status:this.dataObj.otherParams.processInfo.status,
                shDesc:this.dataObj.otherParams.processInfo.shDesc,
                id:this.dataObj.otherParams.processInfo.id
            }
        });
        if(res.result){
            this.dataObj.otherParams.processInfo.visible=false;
            this.utils.Tools.success();
            this.proxy.pageListRef.queryHandler();
        }
    }
}